import {wordSearchDataA, wordSearchDataB} from './RawData.js';


// Raw data is character lines - but they need
// to be all the same length.  This pads lines
// to ensure they are.
export const normaliseRawData = (rawData) => {
    
    const lines     = rawData.split("\n");
    const lengths   = lines.map( l => l.length);
    const maxLength = Math.max ( ...lengths );

    const normalisedLines = lines.reduce( (acc,line,idx) => {
        const endOfLine = (idx === lines.length-1 ? "" : "\n");
        if(line.length === maxLength){
        // We don't want a new line at the end
        // of the very last line of data.
        return acc + line + endOfLine;          
        }
        else if(idx === 0){
        return acc + line.padStart(maxLength,"ㅤ") + endOfLine;
        }
        else{
        return acc + line.padEnd(maxLength,"ㅤ") + endOfLine;
        } }, "");
    return [normalisedLines,maxLength];
}

const PushToArray = (array) => (val) => {
    array.push(val);
    return array;
}
 

// We create arrays holding meta data and the strings to search.
export const normalisedDataToSearchData = (normalisedData) => {
    const lines = normalisedData.split("\n");
    const numCols = lines.length === 0 ? 0 : lines[0].length;
    const numRows = lines.length;
    // Horizontal strings - straight forward since they are just the rows.
    const hLines = lines.reduce( (acc, line,row) => PushToArray(acc)({row:row,text:line}), [] );
    // For the vertical strings we need more work, in effect appending characters at
    // the same position in each row.
    const vLines = hLines.reduce((acc, line, i) => [...line.text].reduce( (acc,char,j) => { acc[j].text = acc[j].text + char; return acc; }, acc), Array.from( {length:numCols}, (_, i) => { return {col:i, text:''} }));

    const dLinesPlus45 = function(hLine,vLine){
        // Going down and to the right so first diagonal is bottom left.
        let [currentStartRow, currentStartCol] = [numRows-1, 0 ];
        let diags = [];
        while(currentStartRow !== 0 || currentStartCol !== numCols){
            // Do a single diagonal with start row and column and move
            // down 1 and across 1 to get the next.  But not beyond the
            // bounds of the grid so to speak.
            let thisDiag = {text:"", diag:[]};
            let [currentRow, currentCol] = [0,0];
            for([currentRow, currentCol] = [currentStartRow,currentStartCol] ; currentRow < numRows && currentCol < numCols ; currentRow++, currentCol++){
                thisDiag.diag.push({row:currentRow,col:currentCol});
                thisDiag.text = thisDiag.text + hLine[currentRow].text.charAt(currentCol);
                //console.log(currentRow,currentCol);
            }
            diags.push(thisDiag);
            
            // The next diagonal to do.  Once we moved up from the bottom left to the top
            //( so this diagonal i.e. [0,0], [1,1]...) we move along (increment the start column)
            // but the start row now stays at the top (0).            
            [currentStartRow, currentStartCol] = currentStartRow > 0 ? [currentStartRow -1, 0] : [0, currentStartCol+1];
            //console.log("");
        }
        return diags;
    };


    const dLinesMinus45 = function(hLine,vLine){
        // Going up and to the left so start top left
        let [currentStartRow, currentStartCol] = [0,0];
        let diags = [];
        while(currentStartCol < numCols){
            let [currentRow, currentCol] = [0,0];
            // So this time move up a row and across a column to the next item
            // on the diagonal.  Watch the grid bounds though
            let thisDiag = {text:"", diag:[]};
            for([currentRow,currentCol] = [currentStartRow,currentStartCol] ; currentRow >= 0 && currentCol < numCols ; currentRow--, currentCol++){
                thisDiag.diag.push({row:currentRow,col:currentCol});
                thisDiag.text = thisDiag.text + hLine[currentRow].text.charAt(currentCol);
                //console.log(currentRow,currentCol);
            }
            diags.push(thisDiag);
            
            // Once the start row hits the bottom the start of the diagonal moves along
            // this last row (start row stays at numRows-1).
            [currentStartRow,currentStartCol] = currentStartRow === numRows-1 ? [numRows-1, currentStartCol +1] : [currentStartRow+1, 0];
            //console.log("");
        }
        return diags;
    };

    const dLinesM45 = dLinesMinus45(hLines,vLines);
    const dLinesP45 = dLinesPlus45(hLines,vLines);

    return [hLines, vLines, dLinesP45, dLinesM45];
}


const findQueryWord = hLines => vLines => dLinesP45 => dLinesM45 => queryWord => {
    
    const hFound = hLines.reduce( (acc, hLine, idx ) => {
        const str = hLine.text;
        let foundAtPos = str.indexOf(queryWord, 0);        
        while(foundAtPos >= 0) {
            acc.push({query:queryWord, row:idx, col:foundAtPos, length:queryWord.length, angle:0 })            
            foundAtPos = str.indexOf(queryWord, foundAtPos + 1);
        }
        return acc;
    },[]);

    const vFound = vLines.reduce( (acc, vLine, idx ) => {
        const str = vLine.text;
        let foundAtPos = str.indexOf(queryWord, 0);        
        while(foundAtPos >= 0) {
            acc.push({query:queryWord, row:foundAtPos, col:idx, length:queryWord.length, angle:90 })            
            foundAtPos = str.indexOf(queryWord, foundAtPos + 1);
        }
        return acc;
    },[]);

    const dFoundP45 = dLinesP45.reduce( (acc, dLine, idx ) => {
        const str = dLine.text;
        let foundAtPos = str.indexOf(queryWord, 0);        
        while(foundAtPos >= 0) {
            acc.push({query:queryWord, row:dLine.diag[foundAtPos].row, col:dLine.diag[foundAtPos].col, length:queryWord.length, angle:45 })            
            foundAtPos = str.indexOf(queryWord, foundAtPos + 1);
        }
        return acc;
    },[]);

    const dFoundM45 = dLinesM45.reduce( (acc, dLine, idx ) => {
        const str = dLine.text;
        let foundAtPos = str.indexOf(queryWord, 0);        
        while(foundAtPos >= 0) {
            acc.push({query:queryWord, row:dLine.diag[foundAtPos].row, col:dLine.diag[foundAtPos].col, length:queryWord.length, angle:-45 })            
            foundAtPos = str.indexOf(queryWord, foundAtPos + 1);
        }
        return acc;
    },[]);

    return [hFound, vFound, dFoundP45, dFoundM45].flat();
}

const removeItem =  array => item =>{
    let i = array.length;
    while (i--) {
        if (array[i] === item) {
            array.splice(array.indexOf(item), 1);
        }
    }
    return array;
}

const getSearcher = wordSquare => queryWords => {
    const [normalisedData, _]   = normaliseRawData(wordSquare.replaceAll('\r', ''));
    const [hLines, vLines, dLinesP45, dLinesM45]      = normalisedDataToSearchData(normalisedData);
    const queries = queryWords.replaceAll("\r", "").split("\n");
    const searcher = findQueryWord(hLines)(vLines)(dLinesP45)(dLinesM45);
    return [searcher, queries];
}

export const searchData = (wordFormatter) => (wordSquare) => (queryWords) => {
    
    const [searcher, queries] = getSearcher(wordSquare)(queryWords);
    
    const queriesPlusReversed = [queries, queries.map( q => [...q].reverse().join(""))].flat();

    const found = queries.reduce( (acc, q, idx) => PushToArray(acc)(searcher(q)), [] ).flat();
    const words = found.map( f => wordFormatter(f.query)(f.row)(f.col)(f.length)(f.angle)(false));    
    
    // We reverse any queries not found.
    const notFound = words.map( w => w.word).reduce( (acc,q,i) => removeItem(acc)(q), [...queries] );
    const reverseQueries = notFound.map( s => [...s].reverse().join(""));
    // And search again.
    const reverseFound = reverseQueries.reduce( (acc, q, idx) => PushToArray(acc)(searcher(q)), [] ).flat();
    const reverseWords = reverseFound.map( f => wordFormatter(f.query)(f.row)(f.col)(f.length)(f.angle)(true));


    return [words, reverseWords].flat();
  }
