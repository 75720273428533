
    export const makeWordHighlight = grid => word => startRow => startCol => length => a => isReversed => {
      const angle = a < 0 ? 360 + a : a;
      const rowColInrementMap = new Map([[0, [0,1]],[45, [1,1]],[90, [1,0]],[135, [1,-1]],[180, [0,-1]],[225, [-1,-1]],[270, [-1,0]],[315, [-1,1]],[360, [0,1]]]);
      const [rowInc,colInc] = rowColInrementMap.get(angle);
      const temp = Array.from({length:length}, (_,i) => [[startRow + i*rowInc, startCol+i*colInc], (grid[startRow + i*rowInc][startCol+i*colInc]).letter ]);
      const {gridCoords,word} = temp.reduce( (acc, t) => {const [c,l] = t; acc.gridCoords.push(c);acc.word += l;return acc; }, {gridCoords:[], word:"" } );
      return { word:word, gridCoords:gridCoords, angle:angle, isReversed: isReversed};
    }

   export const makeWordHighlight1 = grid => word => startRow => startCol => length => angle => {
     
    const [rowCount, colCount] = [grid.length, grid.length > 0 ? grid[0].length : 0];

    const getMinus45WrapPos = () =>  [startRow + startCol >= rowCount ? rowCount-1 : startRow + startCol, startCol - (rowCount-startRow-1) < 0 ? 0 : startCol - (rowCount-startRow-1)];
    const getPlus45WrapPos = () => (startCol > startRow ? [0, startCol-startRow] : [startRow-startCol,0]);
    
    const [rowWrap, colWrap] = angle === 45 ? getPlus45WrapPos() : getMinus45WrapPos();

    const rowIncrement = angle === 0 ? 0 : (angle === 45 || angle === 90 ? 1 : -1);
    const colIncrement = angle === 90 ? 0 : (angle === 45 || angle === 0 ? 1 : 1);

    let currentRow = startRow;
    let currentCol = startCol;

    return { word:word,
      gridCoords:Array.from({length:length}, (_,i) => {
      [currentRow, currentCol] = i === 0 ? [currentRow, currentCol] : [currentRow+rowIncrement,currentCol+colIncrement];
      if(angle === 90 || angle === 0){
        currentRow = currentRow >= rowCount ? 0 : currentRow;
        currentCol = currentCol >= colCount ? 0 : currentCol;
      }
      else{
        if(currentRow >= rowCount || currentCol >= colCount || currentRow < 0){
          [currentRow,currentCol] = [rowWrap,colWrap];
        }
      }        
      const rc = [currentRow,currentCol];
      return rc;
    }),
    angle:angle
    }
  }









/**
 * Liang-Barsky function by Daniel White 
 * 
 * @link http://www.skytopia.com/project/articles/compsci/clipping.html
 *
 * @param  {number}        x0
 * @param  {number}        y0
 * @param  {number}        x1
 * @param  {number}        y1
 * @param  {array<number>} bbox
 * @return {array<array<number>>|null}
 */
 //export const  liangBarsky =  (x0, y0, x1, y1, bbox) => {
  export const  liangBarsky =  bbox => (startCoord) => (endCoord) => {
  const [x0, y0, x1, y1]   = [startCoord,endCoord].flat();
  const [xmin, xmax, ymin, ymax] = bbox;
  var t0 = 0, t1 = 1;
  var dx = x1 - x0, dy = y1 - y0;
  var p, q, r;

  for(var edge = 0; edge < 4; edge++) {   // Traverse through left, right, bottom, top edges.
    if (edge === 0) { p = -dx; q = -(xmin - x0); }
    if (edge === 1) { p =  dx; q =  (xmax - x0); }
    if (edge === 2) { p = -dy; q = -(ymin - y0); }
    if (edge === 3) { p =  dy; q =  (ymax - y0); }

    r = q / p;

    if (p === 0 && q < 0) return null;   // Don't draw line at all. (parallel line outside)

    if(p < 0) {
      if (r > t1) return null;     // Don't draw line at all.
      else if (r > t0) t0 = r;     // Line is clipped!
    } else if (p > 0) {
      if(r < t0) return null;      // Don't draw line at all.
      else if (r < t1) t1 = r;     // Line is clipped!
    }
  }

  return [
    [x0 + t0 * dx, y0 + t0 * dy],
    [x0 + t1 * dx, y0 + t1 * dy]
  ];
}







const rootElement = document.querySelector(':root');

// Create a function for getting a variable value
// Assumes units of px at the moment.
export const getCSSvariableValue = variable => getComputedStyle(rootElement).getPropertyValue(variable).replace('px', '');