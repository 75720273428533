

export const wordSearchDataB = `UWDPNNQWSEISOLEUCINENCOLCNZANPEYUURPOEPMOEOSAANCUTUUOSMUIDALLAPLTHEMRDLU
PRNPOERTSARRFLUORINEOKNKOHCOEIKRTAANTNOYUNHEGMQURHMUTVNSIAMHOMNUEERPIBRN
QPGPHOSPHORUSRDOMKGURUIRNNSIMOOIADUMKRYPTONLEHEEMITEPIIITMOUIMVMOOMEARLI
UILMUISSATOPYLNEROLQONRAIROORTTAIEQOOETTMNNEICCANOIRYDBIRSENPSDSYHOEMIMR
AOUUGAWORARGONOBRACUBMSUUCEHMAITIMEPMHRDIRRNLGBSSNTIRDNAMDOLUYTMVPENLLUE
RMIMIAUNOSPQOOCDRILSUAOQMSCANDIUMBGKRAUQPOTIYEOTAIMITMMUIRECEEUIYAIMMAIN
KGMUTNIOBNMRGNYSNTEIGUCNOGNICKELLMNIUKCSDISUINTRUNENMUINEHTUREMIDNNNEHTN
NMFEITAEDURRSHPAUNCIRUOWRMUIRTTYBCAUNNRBUOTMNITONEMUIRABLDITEEMTDIOUMAEU
LUSIRCRKIOXYGENSILICONCONMIEMIKSADROYOENLTIURNONDHIDNTENTOYYIINIURNMULNA
MIMONYIHLMLKSLACANPLSMEDRSMKMBMGQRTMNAPKGAUPIAMTUSOIBCANDPNEINUNTPUEEUHI
ULGALETOORRYEAUCGRNHUIITICLNTERREUSANLPIPUUNELQIYHMMSMUIBOINIMBCCNNTDLCT
UEULGIPHOIGNUENAUIOLUMGENGUBMVMOMRENISORYTPGNAUURHIRNENHMMUIHTEMORPPISEI
NHINLNRTNEMHLLFCUINAEREIYMNDQNARMUMGNUCSDLATILAMDMIARETINAAUELAREUUERMTI
NUGKRHENIROLHCNEWOHOEIZOETAQCRUNAIEAEYETNLAQNYRLPTHELMTIRIMIEUMDREETYNIU
MNAHOEUSCURXEPSCIENTISTFIPLNTOSURGNNNSCRLUEAONKAIOITINIEHNIIMUNAHTNALAIE
RXOUESEUIDSEOQCHRISTMASPIQKETCTRTNUETIMISTNIEENOHBUNASMTORPOEUDDIINMMMON
IHCILLUEQUSNRUPUZZLEUNUIRUBIDIUMONOSGHUGARLARHPANBNOERMUISEACADMIUMUPOIY
UUNMCIECUEMOUCPOIUUSEMLOIICLEEUIOOIEGMUMRUMPHPIAAATIAHMTMMSLIILEILRINYMV
GLMUINIMULANMELECTRONNEUTRINOTAETICCIQONIOOGTIRYMUNEDBYLOMMITGLUTAMINERN
ETATRAPSAIEUTYNMOEIMUUGUNNMCUENONUNEMUTITSIHTRIOBMIRNCMFNRMIMTMANIIOIOII
LYUMMRUGAEMUINEHRFTIANLYOUPLACUTORUOETINTAEMUIDDONSEUMEAEBNOUSEMURSIMMER
SAATUNOUSMUAIUTUORANAURNCPUIRNNMSNEMWAEMAICNMFUENDNNMENDELEVIUMGPINCRYUR
EMUIMLOHSLAOMISORNUAUTENNNRAHUIUORNMMFUINCUNAEEFSENIRESNRARNCHLCSEOCUVEN
UUAADHEIRMUMNMGNTIUNAHAOTINTNLPIBUUCUINSOONUIBANOBOSUYNBMMUINBUDRICEBSEA
OMYOLUAOSMIUMUNMUIPORUEFINTNNHULZILTLATEDZMUESNCAINSMMAAMIHIEHSUMEARIMNM
MHNNUNFUIRTIUIUAEURIMLNTEILIOSRLNINNOEEIMRRRTLUDNOGEMOISEABORGIUMIGRRONS
ARGININEHEOSDITUPIRUUIRANMNTSMUAUULBIITUNTKNERDAIMHNAEOISLLIWRAOEOERMSLE
UUIRSNSTNNMOBIMSTOIUIUTUCKOTMERHDOTNTFIIIEIZIBOEIANNRRISUNBOAUNSMBGRNCSC
IPTTESEISRRRRLUGNDGUNMASTNSBAUMTMOIWMNCHLMLUULALBSCESNITEROBLVNERSOOOOND
BETUINIUUMEPAMITIOUYFIISRIOOIOUUUUNMUICIREMARMIUEIHTMUTCNEMSSIUULUDESVLA
URTENMEIUTASGNMRLOTIAGDHNMBPTIITMULTITULNTRIDAEMIIDSMEISOEDFMRTTRMVNOINR
EBTNMROLTRMYLEIHNMUTHRTMAIWNINREAIPMNMLUSNIACIOUIVNLMENEIPRGGHNGBFETBUUM
LIEUUTMITUUDUYTTERBIUMTOESPMOIOMEEOUINRCTOUNTAIILAIONNGNVIEIEILLBLBGSMOS
MULATNATIIIMTUIUTUIOTMMMNMTLIRHTNSAITATAUPMRRPRNILIEBIMTATERUEGUNEIEGUGT
UMTTBDHNTRRFAUBMMPTAUEMIIUOAULTMNOICCDNAUEHABLRRBIRIUEDNCVFENMEOURNNGIAA
AASESFIIEIAIMNNMMRIYMLONLPUNTTUPTOTNANTCBTDDOIUOENIARTLIIOIORIINSOAIINND
NUEMTLSIETMEAGMGULGRGSIUOAFSUIOSTMIATDITNLOINAMFIEVROSEIRCMUOMCIIVEUHOET
UUATOIRAERAATAUYTODUEASYRUINMHNNOBEROEUIMIKUEFIIEAOESYVDUDPGUOHISIVMGHSI
UMIDUUUTAUSMEENSRUMCMSILPIBRIIIEIROFRNLNUMRMTFNLSMEHICIOUMNVMIUMUUSAAISU
IIAMDIIMUNITALPMDINRLIRIIOEENTROUUMAPREILMNUIINAORUIIUASPARAGINEAMGNANOM
TGANEUULGEUETHORLTTEUBUILFTUSLUUNIMSTNTURPTSANNCRAIUMUIRHOBNSNOITBRMSGNN
MUONNEUTRINOETURNIDMIBTIHTUMSIBOTNAFBENMAENINALACGLYCINEPSSLIVERMORIUMUH`;

export const queryWordsB = `ARGININE
ASPARAGINE
ASPARTATE
CYSTEINE
GLUTAMATE
GLUTAMINE
GLYCINE
HISTIDINE
ISOLEUCINE
LEUCINE
LYSINE
METHIONINE
PHENYLALANINE
PROLINE
PROLINE
PYROGLUTAMATIC
SERINE
THREONINE
TRYPTOPHAN
TYROSINE
VALINE
ACTINIUM
ALUMINIUM
AMERICIUM
ANTIMONY
ARGON
ARSENIC
ASTATINE
BARIUM
BERKELIUM
BERYLLIUM
BISMUTH
BOHRIUM
BORON
BROMINE
CADMIUM
CAESIUM
CALCIUM
CALIFORNIUM
CARBON
CERIUM
CHLORINE
CHROMIUM
COBALT
COPERNICIUM
COPPER
CURIUM
DARMSTADTIUM
DUBNIUM
DYSPROSIUM
EINSTEINIUM
ERBIUM
EUROPIUM
FERMIUM
FLEROVIUM
FLUORINE
FRANCIUM
GADOLINIUM
GALLIUM
GERMANIUM
GOLD
HAFNIUM
HASSIUM
HELIUM
HOLMIUM
HYDROGEN
INDIUM
IODINE
IRIDIUM
IRON
KRYPTON
LANTHANUM
LAWRENCIUM
LEAD
LITHIUM
LIVERMORIUM
LUTETIUM
MAGNESIUM
MANGANESE
MEITNERIUM
MENDELEVIUM
MERCURY
MOLYBDENUM
MOSCOVIUM
NEODYMIUM
NEON
NEPTUNIUM
NICKEL
NIHONIUM
NIOBIUM
NITROGEN
NOBELIUM
OGANESSON
OSMIUM
OXYGEN
PALLADIUM
PHOSPHORUS
PLATINUM
PLUTONIUM
POLONIUM
POTASSIUM
PRASEODYMIUM
PROMETHIUM
PROTACTINIUM
RADIUM
RADON
RHENIUM
RHODIUM
ROENTGENIUM
RUBIDIUM
RUTHENIUM
RUTHERFORDIUM
SAMARIUM
SCANDIUM
SEABORGIUM
SELENIUM
SILICON
SILVER
SODIUM
STRONTIUM
SULPHUR
TANTALUM
TECHNETIUM
TELLURIUM
TENNESSINE
TERBIUM
THALLIUM
THORIUM
THULIUM
TIN
TITANIUM
TUNGSTEN
URANIUM
VANADIUM
XENON
YTTERBIUM
YTTRIUM
ZINC
ZIRCONIUM
BOTTOM
CHARM
DOWN
STRANGE
TOP
UP
BOSON
ELECTRON
GLUON
GRAVITON
HIGGS
MUON
NEUTRINO
PHOTON
TAU`;

export const wordSearchDataA = `ABCDEFDFGDFGHJGHJHJKHJSDGDFDFGG
GHJKLMDFGBFHWEFJGHGHGJSDFGDFHFH
NOPQRST6Y56YDTUYTTYHRTFGHFGTSEA
TUVWXYRGTGTTDRERKGHJHJFGHFGHGHG
HJTYTRYRTHHHDHFJHGHJGHJFFGHFGHH
HJDFDFGHJTRRFGHFGHFGHJGHJGHJGHH
RTGRTHTYJHGHYUKLJKYUFTHDFGHFGHG
HJDFDFGHJTRRFGHFGHFGHJGHJGHJGHH
HJDFDFGHJTRRFGHFGHFGHJGHJGHJGHH
HJDFDFGHJTRRFGHFGHFGHJGHJGHJGHH
HJDFDFGHJTRRFGHFGHFGHJGHJGHJGHH
HJDFDFGHJTRRFGHFGHFGHJGHJGHJGHH
HJDFDFGHJTRRFGHFGHFGHJGHJGHJGHH`;

export const wordSearchDataC = `JSDGDFDFGG
JSDFGDFHFH
NOPQRST6Y5
TUVWXYRGTG
HJTYTRYRTH
HJDFDFGHJT
RTGRTHTYJH
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT
HJDFDFGHJT`;


export default wordSearchDataB;