// Addapted from here https://stackoverflow.com/questions/68395228/change-width-on-mousedown-and-mousemove-in-react


import { useEffect, useCallback, useRef} from 'react';


const useMouseDrag = (hostId, handlers) => {
  
  // Preserve dragstate between invocations.
  const dragState       = useRef({dragging:false,mouseStart:[0,0],mouseCurrent:[0,0]});

  // Convert screen coordinates (from the mouse event)
  // to client (html element) coordinates.
  const getHostEventClientCoords = useCallback(e => {      
    const rect = document.getElementById(hostId).getBoundingClientRect();
    return [e.clientX - rect.left,e.clientY - rect.top];
  },[hostId]);

  const doDown = useCallback(e => {      
    dragState.current.mouseStart = getHostEventClientCoords(e);
    
    if(handlers && handlers.mouseStartDrag){          
      handlers.mouseStartDrag(dragState.current.mouseStart)(dragState.current.mouseStart);
    }      
    dragState.current.dragging = true;
  },[getHostEventClientCoords,handlers]);

  const doMove = useCallback(e => {
    dragState.current.mousePos = getHostEventClientCoords(e);
    if (dragState.current.dragging) {
      // Call the external (mouse drag) handler
      if(handlers && handlers.mouseDrag){          
        handlers.mouseDrag(dragState.current.mouseStart)(getHostEventClientCoords(e));
      }
    }
  },[getHostEventClientCoords,handlers]);

  const doUp = useCallback(e => {
    dragState.current.dragging = false;
    if(handlers && handlers.mouseEndDrag){
      handlers.mouseEndDrag(dragState.current.mouseStart)(getHostEventClientCoords(e));
    }
  },[getHostEventClientCoords,handlers]);

  const handleMouseDown = useCallback(e => {
    if(e.which === 1){
      doDown(e);
    }
  },[doDown]);

  const handleMouseMove = useCallback(e => {            
    if(e.which === 1){
      doMove(e);
    }
  },[doMove]);

  const handleMouseUp = useCallback(e => {
    if(e.which === 1){
      doUp(e);
    }
  },[doUp]);

  const handleTouchStart = useCallback(e => {
      e.preventDefault();
      const em = e.touches[0];
      dragState.current.mouseCurrent = {clientX:em.clientX, clientY:em.clientY};
      doDown(em);
  },[doDown]);

  const handleTouchMove = useCallback(e => {
      e.preventDefault();
      const em = e.touches[0];
      dragState.current.mouseCurrent = {clientX:em.clientX, clientY:em.clientY};
      doMove(em);
  },[doMove]);

  const handleTouchEnd = useCallback(e => {
      e.preventDefault();
      doUp(dragState.current.mouseCurrent);
  },[doUp]);


  useEffect(() => {
    // window because we want to handle events if we leave
    // (i.e. drag out of) the html element
    
    const host = document.getElementById(hostId);

    host.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);

    host.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      host.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);

      host.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);

    };
});

  return [dragState];
};

export default useMouseDrag;