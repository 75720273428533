import './WordSearchGrid.css';

import React from 'react';
import { useState, Fragment, useRef} from 'react';
import WordSearchGridItems from './WordSearchGridItems';
import {normaliseRawData,  searchData} from './Search';
import { makeWordHighlight, getCSSvariableValue, liangBarsky } from './utils';
import MouseDragRect from './MouseDragRect';
import HiddenWordList from './HiddenWordList';


// This is the container for the grid items each item being an
// individual letter of the word square.  The component
// has the gridTemplate style which we calculate dynamically
// according to the size of the word square.
const WordSearchGrid = (props) => {

    const letterSize          = props.wordSearchProps.letterSize;
    const letterHighlightSize = props.wordSearchProps.letterHighlightSize;    
    const outerPadding        = props.wordSearchProps.gridPadding;
    const fontInfo            = props.wordSearchProps.fontInfo;

    const gridItemsRef = useRef();
    const selectedWords = useRef([]);
    
    // Raw data is a load of lines of characters
    // as a single string.  Grid data is an array
    // of arrays. Each array in the array is a line
    // and each array entry in a line is an object -
    // an id and a letter.
    const rawDataToGridData = (dataOrig) => {
      
      // Make sure each line is the same length (by padding).
      // Just call this normalising the data.
      
      //const [data,rowLength] = normaliseRawData(dataOrig.replaceAll('\r', ''));
      const [data,rowLength] = normaliseRawData(dataOrig.replace(/\r/g, ''));

      // Now we make a list of objects consisteing of
      // a letter and an id. The id gives the row and column position
      const gridData = data.split("\n").map( (line,row) => 
        {
            const cols = line.split('');
            const items = cols.map( (letter,col) =>
            { 
              return {row:row, col:col, id:"item" + (row+1) + "-" + (col+1), letter:letter};
            });
            return items;
        });

        const width = letterSize*rowLength;
        const height = letterSize*gridData.length;
        return [{grid:gridData,width:width,height:height}, rowLength];
    }
    
    const [[{grid,width,height}, rowLength],updateGrid] = useState(rawDataToGridData(props.wordSearchProps.wordSearchData));
    
    // Make the edges open (interval).  Because calculation
    // a grid square on the edge can end up outside the grid.
    const boundCoords = liangBarsky([1,width-1,1,height-1]);
    
    // The handler will be passed through to each letter
    // of the word square (child element of the grid) so
    // it doesn't matter where we paste
    const handleOnPaste = (event) => {
      event.preventDefault();
      // First get the clipboard data from the event.
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
  
      // The first entry is the actual data which
      // we need to extract and use to update the grid.  
      items[0].getAsString( s => updateGrid(rawDataToGridData(s)));
    };

    // This component is the container grid for the
    // word square so we need to give it an appropriate
    // style in accordance with the content (word square letters)
    const getStyle = (letterSquareSize) =>{
      const [rowCount, colCount] = [grid.length, grid.length > 0 ? grid[0].length : 0];
      const gridTemplate = `repeat(${rowCount},${(letterSquareSize)+ 'px'}) / repeat(${colCount},${(letterSquareSize)+ 'px'})`;
      // Make sure padding of the underlying grid is
      // always zero.  Padding is implemented by
      // the parent div element so we don't have to
      // worry about complex calulations when dragging the mouse.
      return {gridTemplate:gridTemplate, padding:0};
    }

    const rowColToIndex = (row,col) => col + row*rowLength;
 

    const showWord = (w) => {
      const type = (i) => i === 0 ? "first" : (i===w.gridCoords.length-1 ? "last" : "mid");
      return w.gridCoords.map( ([row,col],i) => gridItemsRef.current.addHighlightToGridItem({index:rowColToIndex(row,col),angle:w.angle, type:type(i)}));
    }
    const hideWord = (w) => {      
      const type = (i) => i === 0 ? "first" : (i===w.gridCoords.length-1 ? "last" : "mid");
      w.gridCoords.map( ([row,col],i) => gridItemsRef.current.removeHighlightFromGridItem(rowColToIndex(row,col),w.angle, type(i)) );
    }

    const setWords = (showOrHide) => {
       const isReverseWord = false;
       showOrHide(makeWordHighlight(grid) ("")(6)(16)(8)(-45)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(0)(0)(6)(0)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(2)(3)(15)(0)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(0)(15)(8)(45)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(10)(2)(8)(-45)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(2)(5)(8)(90)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(7)(25)(18)(90)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(7)(2)(28)(0)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(2)(0)(28)(45)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(0)(0)(12)(90)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(0)(2)(12)(90)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(22)(22)(35)(45)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(6)(0)(17)(0)(isReverseWord));
       showOrHide(makeWordHighlight(grid) ("")(24)(10)(27)(-45)(isReverseWord));
    }
    
    const [words,_] = useState(searchData(makeWordHighlight(grid))(props.wordSearchProps.wordSearchData)(props.wordSearchProps.hiddenWords));

    let show = false;
    const clicker = () => {
      
      show = !show;
      if(show){
        //setWords(showWord);
        words.map( w => showWord(w));
        selectedWords.current.map(w => showWord(w));
      }
      else{
        //setWords(hideWord);
        words.map( w => hideWord(w));
        selectedWords.current.map(w => hideWord(w));
      }
    }


    // Little helper to get the row and column
    // corresponding to the coordinates.
    const screenCoordsToRowCol = coords => [Math.floor( (coords[1])/letterSize), Math.floor( (coords[0])/letterSize )];

    const gridCoordinatesToBoundingRect = startCoords => endCoords => {
     
      const highlightOffset = (letterSize - letterHighlightSize) / 2;

      // Get the centre for a given row and column      
      const rowColToCentre = coords => [coords[1]*letterSize + letterSize/2, coords[0]*letterSize + letterSize/2 ];
      
      // Gets the row and column of the start and end
      const [sRow, sCol] = screenCoordsToRowCol(startCoords);


      const getRectDetails = () => {
        
        const [sx,sy] = rowColToCentre([sRow,sCol]);
        const [ex,ey] = endCoords;
        const dx      = (ex-sx);
        const dy      = (ey-sy);

        // 0 to 90 is zeroth quadrant, 90 to 180 1st and so on.
        const getQuadrant = () => dx > 0 ? ( dy > 0 ? 0 : 3) : ( dy > 0 ? 1 : 2 );


        // Diagonal, vertical and horizontal snap conditions - so
        // the drag rectangle snaps into place accordingly
        const snapD  = (dx,dy) => Math.abs(dx-dy) < letterSize;
        const snapH   = (dx,dy) => dy < letterSize;
        const snapV  = (dx,dy) => dx < letterSize;

        // Angle is calculated to be in the zeroth or 3rd quad.
        // Otherwise we need to adjust according to the correct quadrant
        const adjustAngle = (angle,quadrant) => quadrant===0 || quadrant === 3? angle : ( quadrant===2?-Math.PI-angle:Math.PI-angle );
        const angleToPosAngleDegrees    = angle => 180/Math.PI * (angle < 0 ? 2*Math.PI + angle : angle);

        const snapToAngle = (angle,quadrant,dx,dy)  => snapD(dx,dy) ? 45 + quadrant * 90 : ( snapH(dx,dy) ? (quadrant === 1 || quadrant === 2 ? 180 : 0) : (snapV(dx,dy) ? (quadrant === 0 || quadrant === 1 ? 90 : 270) : angle) );

        
        const length  = Math.sqrt( dx*dx + dy*dy)
        const quadrant = getQuadrant(dx,dy)
        const angle   = angleToPosAngleDegrees(adjustAngle(Math.asin(dy/length),quadrant));
        const diagSize = Math.sqrt(2*letterSize*letterSize);
        const isDiag = [45,135,225,315].includes(angle);
        // Adjust the length since the (div) rectangle is the left (side)
        // of a given grid square (letter) which is then rotated by the calculated angle.
        const res = [snapToAngle(angle, quadrant,Math.abs(dx),Math.abs(dy)),length +(isDiag?letterSize:letterSize)];
        return res;
      }

      const [angle,width] = getRectDetails();
      const sLeft = sCol*letterSize;
      const sTop  = sRow*letterSize;
      return {angle:angle,left:sLeft, top:sTop+highlightOffset, width:width, height:letterHighlightSize};
    }

    // If we dragged a valid word then we can check and store
    const validateDragRect = (angle) => (startCoords) => (endCoords) => {   
        
        const isOutsideGrid = coord => coord[0] < 0 || coord[0] > width || coord[1] < 0 || coord[1] > height;
        const words = props.wordSearchProps.hiddenWords.replaceAll("\r", "").split("\n");  
        const boundedEndCoords = isOutsideGrid(endCoords) ? boundCoords(startCoords)(endCoords)[1]: endCoords;

        // Gets the row and column of the start and end
        const [sRow, sCol, eRow, eCol] = [screenCoordsToRowCol(startCoords), screenCoordsToRowCol(boundedEndCoords)].flat();
        
        // For non diagonal one of col diff or row diff will be zero
        // and for diagonal they shouldbe the same but if we managed
        // to go outside the grid there is sometimes an error in
        // the row or column (1 too many) so we take the min here.
        // (Really needs a proper fix though).
        const isDiag = [45,135,225,315].includes(angle);
        const length = (isDiag ? Math.min(Math.abs(eRow-sRow),Math.abs(eCol-sCol)) : Math.max(Math.abs(eRow-sRow),Math.abs(eCol-sCol))) + 1;
        
        if(length > 1){
          const w = makeWordHighlight(grid)("")(sRow)(sCol)(length)(angle)(false);
          
          if(words.includes(w.word)){
            selectedWords.current.push(w);
            showWord(w);
            return true;
          }
        }

        return false;
    }

    const [showQueries,updateShowQueries] = useState(false);
    const toggleShowQueries = () => updateShowQueries(showQueries => !showQueries);

    
    const gridRef = useRef();
    const getLeft         = () => gridRef.current ? gridRef.current.getBoundingClientRect().left : 0;    
    
    return (      
      <Fragment>
        <button onTouchStart={clicker} onClick={clicker} style={{background: "red", width1:50, height:50}}>Flash Embedded Words</button>
        <button onTouchStart={toggleShowQueries} onClick={toggleShowQueries} style={{background: "red", width1:50, height:50}}>Show Queries</button>
        
        <HiddenWordList foundWords={words} left={getLeft()} width={width} show={showQueries}/>
        
        <div className="word-search-grid-borderbox" style={{padding:outerPadding}}>
          <div ref={gridRef} className="word-search-grid" id={props.id} style={getStyle(letterSize)}>
              <WordSearchGridItems ref={gridItemsRef} sizes={{fontInfo:fontInfo, letterSize:letterSize, highlightOffset:(letterSize - letterHighlightSize) / 2, letterHighlightSize:letterHighlightSize}} pasteHandler={handleOnPaste} grid={grid}></WordSearchGridItems>
              <MouseDragRect allRefs={props.allRefs} scale={1.0} letterSize={letterSize} validateDragRect={validateDragRect} getDragRect={gridCoordinatesToBoundingRect} hostId={props.id}/>               
          </div>
        </div>
        
      </Fragment>
    );
}

export default WordSearchGrid;