import './WordSearchGrid.css';

import React from 'react';
import {useState, Fragment, useRef} from 'react';
import useMouseDrag from './useMouseDrag';


const MouseDragRect = (props) => {
    
    const doDragRect = useRef({dragging:false, mouseDown:0});
    const [dragState,setDragging] = useState({isDVH:false,isDragging:false, left:0, top:0, width:10, height:10, angle:0, colour:'transparent'});
    
    const handleDrag = which => startCoords => endCoords => {  
      
        const rect = props.getDragRect(startCoords)(endCoords);
        const isDVH = [0,45,90,135,180,225,270,315,360].includes(rect.angle) ? true : false;

        // So only if the end of dragging do we chack.
        if(which === 'end' && doDragRect.current.dragging){
            const _ = isDVH ? props.validateDragRect(rect.angle)(startCoords)(endCoords) : false;
        }
        
        // Now reset the drag state (or not as the case may be)
        doDragRect.current.dragging = which === 'drag';

        if(doDragRect.current.dragging){        
            document.body.classList.add('move-cursor')
        }
        else{
            document.body.classList.remove('move-cursor')
        }

        setDragging(dragState=>{ return {isDVH:isDVH,isDragging:doDragRect.current.dragging, angle:rect.angle,left:rect.left, top:rect.top, width:rect.width, height:rect.height}});
    }
  
    const [mouseDrag] = useMouseDrag(props.hostId, { mouseStartDrag:handleDrag("start"), mouseEndDrag:handleDrag("end"), mouseDrag:handleDrag("drag") });


    const doDragRectangle = () => {
      if(doDragRect.current.dragging){          
        // Diagonal, vertical or horizontal          
        const colour = dragState.isDVH ? 'white' : 'rgba(0,0,0,0.1)';
        return (
          
          <Fragment>
            <div style={{zIndex:3, border:'1px solid blue', transformOrigin:`${props.letterSize/2 + "px"}`, transform:`rotate(${dragState.angle}deg)`, background:dragState.colour, width:dragState.width,height:dragState.height, position:'absolute', left:dragState.left,top:dragState.top, background:colour}}></div>
          </Fragment>
        );
      }
    }

    return (
        <Fragment>
            {doDragRectangle()}
        </Fragment>
    );
}

export default MouseDragRect;