import './WordSearchGrid.css';

import React from 'react';
import { useState, forwardRef, useImperativeHandle, Fragment} from 'react';


// Two elements, one for the background and one for the border.
// Can have a different angle to support vertical and diagonal words too.
// If the letter (border position) is at the start of a word we need
// a left, top and bottom border. At the end a right top and bottom
// otherwise just a top and bottom. So select the CSS class accordingly.
const WordSquareLetterHighlight = (props) => {

    // Judicious choice of class names in the CSS makes these work.
    const borderPositionToClass     = highLightDetails => "letter-border-" + highLightDetails.type;
    const highlighRotationToClass   = highLightDetails => "highlight-rotation-" + highLightDetails.angle;    
    const backGroundClass           = highLightDetails => "word-square-letter-background-" + highLightDetails.type;

    // These elements are absolutely positioned over the grid so
    // calculate where they need to go to "underlay" the actually grid letter.
    // We have to play a little with z-index in these elements (via CSS class)
    // so the background doesn't obscure the borders of neoghbouring letters.
    // Not absolute positioning is with respect to the border edge so ignores
    // padding. Hence we nee to add it in here too (the extra + makes it a number
    // instead of a string)
    const getLeft = () => (props.gridProps.idx % props.gridProps.colCount) * props.gridProps.letterSize;
    const getTop = () => Math.floor(props.gridProps.idx / props.gridProps.colCount) * props.gridProps.letterSize + props.gridProps.highlightOffset;
  
    // To highlight a letter in the wordsquare we provide a border
    // and background. There is the "base"  style plus background + border
    // which depends on whther the letter is at the beginning, end or in the middle
    // plus a rotation.
    const getBorderHighlightClassList = highLightDetails => [ "word-square-letter-border",
                                                               borderPositionToClass(highLightDetails),
                                                               highlighRotationToClass(highLightDetails) ];


    const getBackgroundHighlightClassList = highLightDetails => [ backGroundClass(highLightDetails),
                                                                   highlighRotationToClass(highLightDetails) ];

     return  ( 
         <Fragment>            
            <div className={getBackgroundHighlightClassList(props.highlightDescriptor).join(' ')} style={ {left:getLeft(), top:getTop()} }></div>
            <div className={getBorderHighlightClassList(props.highlightDescriptor).join(' ')} style={ {left:getLeft(), top:getTop()} }></div>           
         </Fragment>
       );
}

// Represents an individual letter in the grid. Each letter
// can be indiviually highlighted multiple times when it appears
// in a search word (it can be in overlapping words, up, down or diagonal)
export const WordsquareLetter = forwardRef((props,ref) => {
    
    // How to highlight a letter is given by the highlight
    // descriptor (i.e. up, down, diagonal, start of word end of word).
    // These descripotors are held in an array. Note updateDescriptors
    // returned from useState takes a function turning the old state
    // to the new state. See below.
    const [highLightDescriptors, updateDescriptors] = useState( [] );

    // Adds a descriptor to the array of descriptors. So the old state
    // if empty gives an array with one value (the newDescriptor). Otherwise append.
    const appendHighlightDescriptors = newDescriptor => updateDescriptors(currentDescriptorList => currentDescriptorList.length === 0 ? [newDescriptor] : [...currentDescriptorList, newDescriptor]);

    // These are "exported" functions to add
    // or remove a highlight from this letter.
    // (actuall all are removed at the moment)
    useImperativeHandle(ref, () => ({
        addHighlightToLetter(highlightInfo) {
            appendHighlightDescriptors(highlightInfo);
        },
        removeHighlightFromLetter(which) {            
            updateDescriptors([]);
        }
      }));


    // So for each highlight list make a highlight component.
    // If there aren't any it returns nothing.
    const highlightLetter = () => highLightDescriptors.map( (d,i) => <WordSquareLetterHighlight key={i}  gridProps={props.gridProps} highlightDescriptor={d}></WordSquareLetterHighlight>);

    // Return an instance of this component. Reacr will turn it into raw HTML
    return  ( 
        <Fragment>
            { /* So this is the single html element for a letter in the grid */}            
            <div onPaste={props.pasteHandler} className={`word-square-letter ${props.gridProps.fontInfo}`}>{props.letter}</div>            
            {/* Create the highlight component (if needed)  */}
            { highlightLetter()}
        </Fragment>)
});

export default WordsquareLetter;