import './App.css';
import React from 'react';

import WordSearchGrid from './WordSearchGrid';
import Header from './Header';
import WordSearchGrid1 from './WordSearchGrid1';
import {wordSearchDataA, wordSearchDataB, wordSearchDataC, queryWordsB} from './RawData.js';
import { getCSSvariableValue} from './utils';

const App = () => {

  const makeRandomData = (rowCount,colCount) => {
    const randomChar = () => {    
      const characters        = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength    = characters.length;
      return characters.charAt(Math.floor(Math.random() *  charactersLength));
    }
    const letters = Array.from({length: rowCount*colCount}, (_, i) => i%colCount === colCount-1 && Math.floor( i / colCount ) !== rowCount-1? randomChar() + '\n' : randomChar() ).join('');
    
    return letters;
  }



  const wordSearchProps = {
    letterSize:           +getCSSvariableValue('--wordsquare-letter-size'),
    letterHighlightSize:  +getCSSvariableValue('--wordsquare-letter-highlighted-size'),
    gridPadding:          +getCSSvariableValue('--grid-padding-outer'),
    wordSearchData:       wordSearchDataB,
    hiddenWords:          queryWordsB,
    fontInfo:             "word-square-fontA"
  };


  const rowCount = 9;
  const colCount = 12;
  const wordSearchProps1 = {
    letterSize:           72,
    letterHighlightSize:  62,
    gridPadding:          60,
    wordSearchData:       makeRandomData(rowCount,colCount),
    hiddenWords:          queryWordsB,
    fontInfo:             "word-square-fontB"

  };

 

  return (
  <div>
    <WordSearchGrid  id={"word-search-grid1"} wordSearchProps={wordSearchProps1}/>
    <Header></Header>
    <WordSearchGrid id={"word-search-grid"} wordSearchProps={wordSearchProps}/>
  </div>
  );
}

export default App;
