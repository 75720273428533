import './WordSearchGrid.css';

import React from 'react';
import {Fragment, forwardRef, createRef, useImperativeHandle} from 'react';
import {WordsquareLetter} from './WordsquareLetter';





// These are the individual items, the letters which are
// displayed as a CSS grid.  The data (i.e. gridData) consists
// of one or more lines of text (the letters with hidden words).
// Each letter is a react WordsquareLetter component each of
// which can be (CSS) styled.  
const WordSearchGridItems = forwardRef((props,ref) =>  {

  const grid          = props.grid;
  const pasteHandler  = props.pasteHandler;  
  const [rowCount, colCount] = [grid.length, grid.length > 0 ? grid[0].length : 0];

  let wordSquareLetterRefs  = Array.from({length: rowCount*colCount}, (_, i) => createRef() );

  const letterSize          = props.sizes.letterSize;

  // Highlight is a background and border which are positioned absolutely
  // and we need to shift down slightly. Well to centre in the grid item (letter).
  const highlightOffset     = props.sizes.highlightOffset;  
  const fontInfo            = props.sizes.fontInfo;

  useImperativeHandle(ref, () => ({
    addHighlightToGridItem(highlightInfo) {      
      wordSquareLetterRefs[highlightInfo.index].current.addHighlightToLetter(highlightInfo);
    },
    removeHighlightFromGridItem(which) {      
      wordSquareLetterRefs[which].current.removeHighlightFromLetter(which);
    }
  }));

  // Individual letters for the word square.
  const makeGridItems = () =>
    grid.flat().map( (gridItem, idx) => <WordsquareLetter clickHandler={props.clickHandler} key={idx}
                                        gridProps={{idx:idx, rowCount:rowCount, colCount:colCount, fontInfo:fontInfo, letterSize:letterSize, highlightOffset:highlightOffset}} ref={wordSquareLetterRefs[idx]}
                                        pasteHandler={pasteHandler}
                                        letter={grid[gridItem.row][gridItem.col].letter}/>);

  // Fragment means no superflous container element around the grid items.
  return <Fragment>
          {makeGridItems()}
         </Fragment>
});

export default WordSearchGridItems;
