
import './WordSearchGrid.css';
import React from 'react';
import { useState, useRef} from 'react';
import {wordSearchDataA, wordSearchDataB, wordSearchDataC, queryWordsB} from './RawData.js';
import { ButtonGroup, Button, ToggleButton } from '@mui/material';


const queryWords = queryWordsB;

const HiddenWordList = (props) => {
    console.log("props", props);
    let sampleData = Array.from({length: 13}, (_, i) => Array.from({length:13}, (_,j) =>{ return {id:"item" + (i+1) + "-" + (j+1), letter:"X"};}))

    const [words, updateWords] = useState([]);

    const [{width,height}, updateSize]  = useState({width:props.width, height:200});
    const [{x,y}, updatePosition]       = useState({x:0, y:0});

    const handleOnPaste = (event) => {
      console.log({ event });
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;  

      items[0].getAsString( s => {
        const words = s.split('\r\n');

        updateWords(words)
        });
    };
    
    const pushToArray = (arr,val) => {
        arr.push(val);
        return arr;
    }
    const [buttonStates, setButtonState] = useState({hideMultiplicity:true,multiplicityText:"SHOW MULTIPLES"});

    const doHiddenWords = () =>  {
        const reverseString = (str) => {            
            const rev = str.split("").reverse().join("");
            console.log(str,rev);
            return rev;
        };

        const foundWords        = props.foundWords.reduce( (acc, w,i) => !w.isReversed ? pushToArray(acc, w.word) : pushToArray(acc, (reverseString(w.word))), []);
        const hiddenWordList    = queryWords.replace(/\r/g, '').split("\n");
                            
        const occurrences = foundWords.reduce((acc, word) => {
            acc[word] = (acc[word] || 0) + 1;
            return acc;
        }, {});

        return buttonStates.hideMultiplicity ? hiddenWordList.
                                                    map(w => <div className="word-search-hiddenword" dataNumber="{i}">{w}</div>) :
                                               hiddenWordList.
                                                    map( (w,i) => <div className="word-search-hiddenword" dataNumber="{i}">{occurrences[w] > 1 ? w + `(${occurrences[w]})` : w}</div>);

    }

    return (
        <div  className="word-search-hiddenword-component" style={{display:"block",position:"relative", marginLeft:"auto", marginRight:"auto",
            resize: "both", display:props.show? "flex":"none", width:width, height1:height, transform1:`translate(${x}px,${y}px)`}}>
            <div style={{width:"100%", padding:"5px", borderBottom:"solid 1px black"}}>
            <ButtonGroup variant="contained" style={{padding:"0px", display:"flex",width:"400px", marginLeft:"auto", marginRight:"auto", height:"40px"}} role="group" aria-label="Basic example">
                


            <ToggleButton style={{margin:"2px", color:"white"}} color="primary" selected={buttonStates.hideMultiplicity}
                            onChange={() => {setButtonState( s => { return {hideMultiplicity:!s.hideMultiplicity, multiplicityText:s.hideMultiplicity ? "HIDE MULTIPLES" : "SHOW MULTIPLES"};});}}>{buttonStates.multiplicityText}</ToggleButton>

                <Button style={{margin:"2px"}} color="primary">Middle</Button>
                <Button style={{margin:"2px"}} color="primary">Right</Button>
            </ButtonGroup>
            </div>
            
          <div  className= "word-search-hiddenword-list" style={{position:"relative", marginLeft:"auto", marginRight:"auto"}}>
            {doHiddenWords()}
          </div>
          </div>
    );
}

export default HiddenWordList;

